@charset "utf-8";

/* default */
html {
	/*height:100%;*/
}
body {
	overflow-x: hidden; /*height:100%;*/
	font-family: 'Spartan', 'sans-serif';
	font-size: 12px;
	line-height: 1.3;
	color: #4b4c4c;
	background-color: #1d103b;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	-moz-osx-font-smoothing: gratranslate3d(0, 0, 0) yscale;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
p,
button,
iframe,
th,
td {
	margin: 0;
	padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
	display: block;
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
dt {
	font-weight: normal;
}
img,
fieldset,
iframe,
button {
	border: 0 none;
}
button {
	cursor: pointer;
	background: none;
}
button:focus {
	outline: 0;
}
li {
	list-style: none;
}
button {
	border: 0 none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
th,
td {
	border-collapse: collapse;
}
legend,
caption,
hr {
	display: none;
}
select,
input,
textarea {
}
i,
em,
u,
cite {
	font-style: normal;
}
strong {
	font-weight: normal;
}
img {
	vertical-align: top;
	max-width: 100%;
}
a {
	color: #4b4c4c;
	text-decoration: none;
	outline-offset: -1px;
}

/*!* common *!*/
main {
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}
section {
	max-width: 1440px;
	margin: 0 auto;
}
#contents {
	position: relative;
	min-width: 320px;
	margin: 0 auto;
	min-height: 100%;
}
#contents a {
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
}
#contents a:hover,
#contents a:focus {
	opacity: 0.8;
}

.btn-menu {
	display: none;
}
.mobile-nav {
	display: none;
}
.mobile {
	display: none;
}
.br {
	display: block;
}

.btn-neon {
	display: inline-block;
	border-radius: 56px;
	box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	-webkit-box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	-ms-box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	background: #73eaff;
	letter-spacing: -0.5px;
	color: #323232;
	text-align: center;
	font-weight: bold;
}
.text-neon {
	text-shadow: 0 0 13px #73eaff;
	-webkit-text-shadow: 0 0 13px #73eaff;
	-ms-text-shadow: 0 0 13px #73eaff;
	font-weight: bold;
	letter-spacing: -0.5px;
	color: #fff;
}

/*!* header *!*/
/*;*/
header {
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -720px;
	width: 100%;
	max-width: 1440px;
	z-index: 999;
}
header:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	opacity: 0.3;
	width: 100%;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	border-style: solid;
	border-width: 1px;
	border-image-source: linear-gradient(to right, #61cfff 34%, #eb38ff 91%);
	border-image-slice: 1;
}
.header-inner {
	overflow: visible;
	max-width: 1120px;
	margin: 0 auto;
	padding: 32px 0;
}
.header-inner:after {
	display: block;
	clear: both;
	content: '';
}
.header-inner h1 {
	float: left;
	text-indent: -9999px;
	background: url('./assets/images/new_logo.png') 0 no-repeat;
	background-size: auto 32px;
	width: 32px;
	height: 32px;
}
.header-inner h1 a {
	display: block;
	height: 100%;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
}
.header-inner h1 a:hover,
.header-inner h1 a:focus {
	opacity: 0.2;
}
.header-inner nav {
	float: left;
	display: inline-block;
	vertical-align: middle;
	margin-left: 152px;
}
.header-inner .gnb {
	font-size: 0;
}
.header-inner .gnb li {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-left: 35px;
}
.header-inner .gnb a {
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	line-height: 40px;
}
.header-inner .gnb a:hover,
.header-inner .gnb a:focus {
	color: #73eaff;
}
.header-inner .depth,
.mobile-nav .depth {
	display: none;
	position: absolute;
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 13px 17px 0 rgb(0 0 0 / 5%);
	-webkit-box-shadow: 0 13px 17px 0 rgb(0 0 0 / 5%);
	-ms-box-shadow: 0 13px 17px 0 rgb(0 0 0 / 5%);
	z-index: 1;
}
.header-inner .depth {
	padding: 26px 30px 26px 20px;
}
.header-inner .depth li {
	margin-left: 0;
}
.header-inner .depth li a {
	font-size: 14px;
	font-weight: 600;
	line-height: 2.29;
	color: rgba(50, 50, 50, 0.6);
}
.header-inner .depth li a:hover,
.header-inner .depth li a:focus {
	color: #323232;
}
.header-inner .btn-neon {
	float: left;
	margin-left: 80px;
	width: 120px;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
}

.tit {
	position: relative;
	padding-bottom: 28px;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1px;
	text-align: center;
	color: #8454eb;
}
.tit:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -74px;
	width: 148px;
	height: 4px;
	background: #73eaff;
}
.tit .text-neon {
	display: block;
	margin-top: 16px;
	font-size: 40px;
	line-height: 1.5;
}
.tit.text-c {
	text-align: center;
}

.sub-tit {
	display: block;
	margin-top: 24px;
	font-size: 32px;
	line-height: 1.25;
	color: #19171c;
}
.sub-tit em {
	font-family: 'Montserrat';
	font-weight: bold;
	color: #feac09;
}
.flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* content */

section {
	max-width: 1440px;
	margin: 0 auto;
}
.kv {
	position: relative;
	background: url('./assets/images/bg_kv.png') no-repeat;
	padding-top: 145px;
	padding-bottom: 420px;
}
.kv .text {
	position: relative;
	z-index: 1;
	padding: 0 160px;
}
.kv .text-neon {
	font-size: 60px;
	line-height: 1.33;
}
.kv p {
	margin-top: 15px;
	width: 486px;
	font-size: 16px;
	font-weight: 500;
	line-height: 2;
	color: #fff;
	white-space: pre;
}
.kv .btn-neon {
	margin-top: 64px;
	width: 200px;
	line-height: 56px;
}
.kv video {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.kv figure {
	position: absolute;
	bottom: 0;
	left: 0;
}

.feature01 {
	padding-top: 16px;
	padding-bottom: 250px;
	background: url('./assets/images/bg_about.png') 0 100% no-repeat;
}
.feature01 .tab {
	margin-top: 80px;
	font-size: 0;
	text-align: center;
}
.feature01 .tab li:first-child {
	margin-left: 0;
}
.feature01 .tab li {
	display: inline-block;
	vertical-align: middle;
	margin-left: 24px;
}
.feature01 .tab li a {
	display: inline-block;
	padding: 0 20px;
	border-radius: 12px;
	border: solid 1px #8454eb;
	font-size: 16px;
	font-weight: 500;
	line-height: 54px;
	text-align: center;
	color: #fff;
}
.feature01 .tab .curr a,
.feature01 .tab a:hover,
.feature01 .tab a:focus {
	box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	-webkit-box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	-ms-box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	background: #73eaff;
	border-color: #73eaff;
	color: #323232;
	font-weight: 600;
}
.feature01 .cont-box .cont {
	display: none;
}
.feature01 .cont-box .cont.on {
	display: block;
}
.feature01 .cont-box p {
	margin-top: 48px;
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
	color: #c0c0c0;
	white-space: pre;
}
.feature01 .cont-box figure {
	margin-top: 48px;
	text-align: center;
}

.feature02 {
	padding-top: 116px;
	padding-bottom: 140px;
	background: url('./assets/images/bg_feature02.png') 0 100% no-repeat;
}
.feature02 ul {
	width: 1120px;
	margin: 80px auto 0;
	padding: 56px 48px 63px;
	background: url('./assets/images/bg_feature02-list.png') 0 0 no-repeat;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
}
.feature02 li {
	display: flex;
	justify-content: flex-start;
	margin-top: 72px;
}
.feature02 li:first-child {
	margin-top: 0;
}
.feature02 li strong {
	font-size: 22px;
	font-weight: 600;
	line-height: 1.27;
	color: #fff;
}
.feature02 li p {
	margin-top: 15px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	color: #fff;
	white-space: pre;
}
.feature02 figure {
	flex: none;
	width: 112px;
	margin-right: 40px;
}
.feature02 figure img {
	height: 112px;
}

.feature03 {
	padding: 144px 0;
	background-image: linear-gradient(
		131deg,
		#39197b 0%,
		rgba(57, 25, 123, 0) 94%
	);
}
.feature03 .distrib-wrap {
	max-width: 1120px;
	margin: 80px auto 0;
	border-radius: 40px;
	border-style: solid;
	border-width: 1px; /*border-image-source:linear-gradient(147deg, #61cfff 14%, #eb38ff 96%);border-image-slice:1;*/
}
.feature03 .cont01 {
	display: flex;
	align-items: flex-end;
	padding: 18px 116px 56px 93px;
}
.feature03 .cont01 figure {
	margin-right: 124px;
}
.feature03 .cont01 figure img {
	height: 465px;
}
.feature03 .cont01 div {
	margin-top: 32px;
}
.feature03 .cont01 div img {
	height: 394px;
}
.feature03 .cont01 li {
	margin-top: 16px;
	font-size: 16px;
	line-height: 1.5;
	color: #fff;
}
.feature03 .cont01 li strong {
	display: inline-block;
	width: 107px;
	margin-right: 32px;
	font-weight: 600;
}
.feature03 .cont01 li span {
	font-weight: 500;
}
.feature03 .cont02 {
	position: relative;
	padding: 50px 0 74px;
	margin: 0 56px;
}
.feature03 .cont02:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	border-style: solid;
	border-width: 1px;
	border-image-source: linear-gradient(to right, #61cfff 34%, #eb38ff 91%);
	border-image-slice: 1;
	opacity: 0.3;
}
.feature03 .cont02 strong {
	display: block;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
	color: #fff;
}
.feature03 .cont02 ul {
	overflow: hidden;
	margin: 0 -10px;
	padding-top: 10px;
}
.feature03 .cont02 ul:after {
	display: block;
	display: block;
	clear: both;
}
.feature03 .cont02 li {
	float: left;
	width: 48%;
	margin: 1%;
	padding: 16px;
	border-radius: 12px;
	border: solid 1px #f2f2f2;
	background: #fff;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	color: #323232;
	cursor: pointer;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
}
.feature03 .cont02 li:hover,
.feature03 .cont02 li:focus {
	box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	-webkit-box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	-ms-box-shadow: 0 0 17px 0 rgba(115, 234, 255, 0.7);
	background: #73eaff;
	border-color: #73eaff;
}
.feature03 .cont02 li em {
	display: block;
	font-weight: bold;
}

.feature04 {
	max-width: none;
	padding: 100px 0 88px;
	background: rgba(57, 25, 123, 0.4);
}
.feature04 .flex {
	max-width: 1120px;
	margin: 80px auto 0;
}
.feature04 figure {
	flex: none;
	width: 380px;
	margin-right: 56px;
}
.feature04 figure img {
	height: 280px;
}
.feature04 strong {
	font-size: 22px;
	font-weight: 600;
	line-height: 1.27;
	color: #fff;
}
.feature04 p {
	margin-top: 16px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	color: #fff;
}
.feature04 .btn-neon {
	margin-top: 24px;
	padding: 0 36px;
	line-height: 56px;
}

.feature05 {
	position: relative;
	padding: 144px 0;
}
.feature05 .bg {
	position: absolute;
	top: 522px;
}
.feature05 figure {
	margin-top: 80px;
	text-align: center;
}
.feature05 figure img {
	height: 1068px;
}

.feature06 {
	max-width: none;
	padding: 100px 0 88px;
	background: url('./assets/images/bg_exchanges.png') no-repeat;
	background-size: cover;
}
.feature06 ul {
	margin-top: 52px;
	font-size: 0;
	text-align: center;
}
.feature06 li {
	display: inline-block;
	vertical-align: middle;
	margin: 0 25px;
}
.feature06 li img {
	height: 124px;
}

.feature07 {
	padding: 144px 0;
}
.feature07 ul {
	margin-top: 64px;
	font-size: 0;
	text-align: center;
}
.feature07 li {
	display: inline-block;
	vertical-align: middle;
	margin: 0 16px;
}
.feature07 li img {
	height: 70px;
}

.feature08 {
	position: relative;
	padding: 144px 0;
	max-width: none;
}
.feature08:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	border-style: solid;
	border-width: 1px;
	border-image-source: linear-gradient(to right, #61cfff 34%, #eb38ff 91%);
	border-image-slice: 1;
	opacity: 0.3;
}
.feature08 .slider-w {
	max-width: 1120px;
	margin: 80px auto 0;
}
.feature08 .slider figure {
	width: 500px;
	height: 240px;
	border-radius: 32px;
	box-shadow: 0 37.1px 59.1px 0 rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 0 37.1px 59.1px 0 rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 0 37.1px 59.1px 0 rgba(0, 0, 0, 0.05);
	background: #dadada;
}
.feature08 .bx-wrapper {
	margin-bottom: 0;
	box-shadow: none;
	border: none;
	background: transparent;
}
.feature08 ul {
	overflow: hidden;
}
.feature08 ul:after {
	content: '';
	display: block;
	clear: both;
}
.feature08 li {
	float: left;
	margin-left: 0px;
}
.feature08 li:first-child {
	margin-left: 0px;
}
.feature08 li strong,
.feature08 li span {
	display: block;
	padding-left: 20px;
	font-weight: 600;
}
.feature08 li strong {
	margin-top: 24px;
	font-size: 16px;
	line-height: 1.75;
	color: #fff;
}
.feature08 li span {
	margin-top: 8px;
	font-size: 12px;
	line-height: 1.67;
	color: #8454eb;
}
.feature08 .bx-wrapper .bx-pager {
	font-size: 0;
	padding: 0;
	bottom: -88px;
}
.feature08 .bx-wrapper .bx-pager.bx-default-pager a {
	width: 8px;
	height: 8px;
	margin: 0 4px;
	opacity: 0.2;
	background: #d8d8d8;
}
.feature08 .bx-wrapper .bx-pager.bx-default-pager a.active,
.feature08 .bx-wrapper .bx-pager.bx-default-pager a:focus,
.feature08 .bx-wrapper .bx-pager.bx-default-pager a:hover {
	background: #8454eb;
	opacity: 1;
}
.feature08 .bx-wrapper .bx-controls-direction a {
	margin-top: -48px;
	width: 48px;
	height: 48px;
}
.feature08 .bx-wrapper .bx-prev {
	background: url('./assets/images/btn_slider-prev.png') 0 0 no-repeat;
	background-size: auto 48px;
	left: -86px;
}
.feature08 .bx-wrapper .bx-next {
	background: url('./assets/images/btn_slider-next.png') 0 0 no-repeat;
	background-size: auto 48px;
	right: -86px;
}
.feature08 .bx-wrapper .bx-next:focus,
.feature08 .bx-wrapper .bx-next:hover {
	background-position: 0 0;
}
.feature08 .slider-m {
	display: none;
}

.feature09 {
	max-width: none;
	padding: 144px 0 40px;
	background: url('./assets/images/bg_is-on.png') no-repeat;
	background-size: cover;
}
.feature09 ul {
	max-width: 1280px;
	margin: 80px auto 0;
	text-align: center;
	font-size: 0;
}
.feature09 li {
	display: inline-block;
	vertical-align: top;
	width: 256px;
	margin: 0 50px 104px;
}
.feature09 li figure img {
	height: 120px;
}
.feature09 li p {
	margin-top: 25px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	color: #fff;
}

.feature10 {
	padding: 144px 0 60px;
	background-image: linear-gradient(
		120deg,
		#39197b 0%,
		rgba(57, 25, 123, 0) 96%
	);
}
.feature10 > ul {
	overflow: hidden;
	max-width: 1160px;
	margin: 80px auto 0;
}
.feature10 > ul:after {
	content: '';
	display: block;
	clear: both;
}
.feature10 > ul > li {
	float: left;
	width: 50%;
	display: flex;
	min-height: 230px;
	margin-bottom: 84px;
}
.feature10 figure {
	flex: none;
}
.feature10 .info {
	margin-left: 32px;
	padding-top: 8px;
	color: #fff;
}
.feature10 .info strong {
	display: block;
	font-size: 20px;
	font-weight: 600;
	line-height: 1.2;
}
.feature10 .info span {
	display: block;
	margin-top: 4px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
}
.feature10 .info ul {
	margin-top: 24px;
}
.feature10 .info li {
	position: relative;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	color: #c0c0c0;
}
.feature10 .info li:before {
	content: '- ';
}
.feature10 .info li .br {
	text-indent: 10px;
}

/* footer */
footer {
	background: url('./assets/images/bg_footer.png') no-repeat;
	background-size: cover;
}
footer .inner-box {
	display: flex;
	justify-content: space-between;
	max-width: 1120px;
	margin: 0 auto;
	padding: 48px 0 56px;
}
footer .logo img {
	height: 32px;
}
footer .cont01 p {
	margin-top: 26px;
	width: 220px;
	font-size: 12px;
	font-weight: 500;
	line-height: 2;
	color: #fff;
}
footer .cont02 {
	overflow: hidden;
}
footer .cont02:after {
	content: '';
	display: block;
	clear: both;
}
footer .cont02 > div {
	float: left;
	margin-left: 100px;
}
footer .cont02 > div:first-child {
	margin-left: 0;
}
footer .cont02 strong {
	display: block;
	font-size: 16px;
	font-weight: bold;
	line-height: 1;
	color: #fff;
}
footer .cont02 li {
	margin-top: 24px;
}
footer .cont02 li a {
	font-size: 12px;
	font-weight: 500;
	color: #fff;
}
footer .cont03 {
	width: 280px;
}
footer .cont03 .footer-link {
	font-size: 0;
}
footer .cont03 .footer-link li {
	display: inline-block;
	margin: 0 0 18px 24px;
}
footer .cont03 .footer-link img {
	height: 32px;
}
footer .copy {
	border-top: 1px solid rgba(242, 242, 242, 0.3);
	font-size: 12px;
	line-height: 60px;
	text-align: center;
	color: #fff;
}

/* mobile web */

@media (max-width: 1280px) {
	#contents {
		max-width: 375px;
		margin: 0 auto;
	}
	html.nav-open {
		overflow-y: hidden;
	}
	.pc {
		display: none;
	}
	.mobile {
		display: block;
	}

	.header-inner {
		padding: 24px;
	}
	.header-inner h1 {
		float: none;
	}
	.header-inner nav {
		display: none;
	}
	.header-inner .btn-neon {
		display: none;
	}

	.btn-menu {
		display: block;
		position: absolute;
		top: 24px;
		right: 20px;
		width: 32px;
		height: 32px;
		z-index: 9999;
		background: url('./assets/images/btn_menu.png') no-repeat;
		background-size: auto 32px;
		text-indent: -9999px;
	}
	.mobile-nav {
		overflow-y: scroll;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-ms-box-sizing: border-box;
		display: block;
		padding: 120px 48px 0;
		position: fixed;
		top: 0;
		right: -100%; /*right:0;*/
		height: 100%;
		width: 100%;
		background: #1d103b;
		z-index: 9999;
	}
	.mobile-nav .close {
		position: absolute;
		top: 24px;
		right: 20px;
	}
	.mobile-nav .close img {
		height: 32px;
	}
	.mobile-nav .btn-sns {
		position: absolute;
		bottom: 56px;
		left: 64px;
	}
	.mobile-nav .btn-sns ul {
		font-size: 0;
	}
	.mobile-nav .btn-sns li {
		display: inline-block;
		vertical-align: middle;
		margin-right: 24px;
	}
	.mobile-nav .btn-sns li img {
		height: 24px;
	}

	.mobile-nav .gnb li:first-child {
		margin-top: 0;
	}
	.mobile-nav .gnb li {
		position: relative;
		margin-top: 32px;
	}
	.mobile-nav .gnb li a {
		font-size: 20px;
		line-height: 1.7;
		color: #fff;
	}
	.mobile-nav .gnb li a:hover,
	.mobile-nav .gnb li a:focus {
		text-shadow: 0 0 10px rgba(115, 234, 255, 0.8);
		color: #73eaff;
	}
	.mobile-nav .depth {
		position: absolute;
		background: none;
		border-radius: 0;
		box-shadow: none;
	}
	.mobile-nav .depth li {
		margin-top: 24px !important;
	}
	.mobile-nav .depth li a {
		font-size: 14px;
		font-weight: 500;
		color: #fff;
		line-height: normal;
	}
	.mobile-nav .btn-neon {
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0 48px;
		width: calc(100% - 96px);
		bottom: 56px;
		font-size: 16px;
		line-height: 56px;
	}

	.mobile-br {
		display: block;
	}

	.tit {
		font-size: 14px;
	}
	.tit .text-neon {
		font-size: 32px;
	}

	/* content */
	.kv {
		background: url('./assets/images/bg_kv-m.png') no-repeat;
		padding-top: 110px;
		padding-bottom: 340px;
	}
	.kv .text {
		padding: 0 34px;
	}
	.kv .text-neon {
		letter-spacing: -0.5px;
	}
	.kv p {
		margin-top: 45px;
		width: 308px;
		font-size: 14px;
		line-height: 1.71;
	}
	.kv .btn-neon {
		margin-top: 32px;
		width: 156px;
		line-height: 48px;
	}

	.feature01 {
		padding-top: 80px;
		padding-bottom: 100px;
		background-position: 0 470px;
	}
	.feature01 .tab {
		margin-top: 40px;
	}
	.feature01 .tab li:first-child {
		margin-left: 0;
	}
	.feature01 .tab li:first-child a {
		padding: 0 22px;
	}
	.feature01 .tab li {
		margin: 8px 5px;
	}
	.feature01 .tab li a {
		font-size: 14px;
	}
	.feature01 .cont-box p {
		margin-top: 40px;
		padding: 0 34px;
		font-size: 14px;
		line-height: 1.71;
	}
	.feature01 .cont-box .br {
		display: inline;
	}
	.feature01 .cont-box figure {
		margin-top: 40px;
	}

	.feature02 {
		padding-top: 80px;
		padding-bottom: 80px;
		background: url('./assets/images/bg_feature02-m.png') 0 100% no-repeat;
	}
	.feature02 ul {
		width: 343px;
		margin: 40px auto 0;
		padding: 40px 24px;
		background: url('./assets/images/bg_feature02-list-m.png') 0 0 no-repeat;
		background-size: 343px auto;
	}
	.feature02 li {
		display: block;
		margin-top: 64px;
	}
	.feature02 li strong {
		display: block;
		margin-top: 24px;
		font-size: 20px;
		line-height: 1.4;
	}
	.feature02 li p {
		margin-top: 24px;
	}
	.feature02 figure {
		margin-right: 0;
	}

	.feature03 {
		padding: 80px 0;
	}
	.feature03 .distrib-wrap {
		max-width: 343px;
		margin: 40px auto 0;
	}
	.feature03 .cont01 {
		position: relative;
		display: block;
		padding: 206px 24px 32px;
	}
	.feature03 .cont01 figure {
		margin-right: 0;
	}
	.feature03 .cont01 figure img {
		height: 318px;
	}
	.feature03 .cont01 div {
		margin-top: 40px;
	}
	.feature03 .cont01 div img {
		height: 392px;
	}
	.feature03 .cont01 div ul {
		position: absolute;
		top: 40px;
	}
	.feature03 .cont01 div ul:after {
		content: '';
		position: absolute;
		bottom: -32px;
		left: 0;
		width: 100%;
		border-style: solid;
		border-width: 1px;
		border-image-source: linear-gradient(to right, #61cfff 34%, #eb38ff 91%);
		border-image-slice: 1;
		opacity: 0.3;
	}
	.feature03 .cont01 li {
		font-size: 14px;
	}
	.feature03 .cont01 li strong {
		width: 94px;
	}
	.feature03 .cont02 {
		padding: 40px 0;
		margin: 0 24px;
	}
	.feature03 .cont02 ul {
		overflow: visible;
		margin: 0;
		padding: 0;
	}
	.feature03 .cont02 li {
		float: none;
		width: 100%;
		margin: 16px 0 0 0;
		font-size: 12px;
		word-break: break-all;
	}
	.feature03 .cont02 li em {
		font-size: 14px;
		margin-bottom: 5px;
	}

	.feature04 {
		padding: 80px 34px;
	}
	.feature04 .flex {
		margin-top: 40px;
		display: block;
	}
	.feature04 figure {
		width: 307px;
		margin-right: 0;
	}
	.feature04 figure img {
		height: 226px;
	}
	.feature04 strong {
		display: block;
		margin-top: 40px;
		font-size: 20px;
		line-height: 1.4;
	}
	.feature04 .btn-neon {
		margin-top: 40px;
		padding: 0 18px;
		line-height: 48px;
		font-size: 14px;
	}

	.feature05 {
		padding: 80px 34px 80px 24px;
	}
	.feature05 .bg {
		left: 0;
	}
	.feature05 .bg img {
		height: 576px;
	}
	.feature05 figure {
		margin-top: 40px;
	}
	.feature05 figure img {
		height: 1190px;
	}

	.feature06 {
		padding: 80px 0 88px;
		background: url('./assets/images/bg_exchanges-m.png') no-repeat;
		background-size: auto 100%;
	}
	.feature06 ul {
		margin-top: 72px;
	}

	.feature07 {
		padding: 80px 0 48px;
	}
	.feature07 h2 {
		width: 200px;
		margin: 0 auto;
	}
	.feature07 ul {
		margin-top: 68px;
	}
	.feature07 li {
		display: block;
		margin: 32px 0;
	}

	.feature08 .tit {
		margin-right: 45px;
	}
	/*.feature08 .slider figure {width:307px;height:226px;}*/
	/*.feature08 li {margin-left:0;}*/
	/*.feature08 li strong,*/
	/*.feature08 li span {padding-left:16px;}*/
	/*.feature08 li strong {font-size:14px;line-height:2;}*/
	/*.feature08 .bx-wrapper .bx-pager {bottom:-40px;}*/
	/*.feature08 .bx-wrapper .bx-controls-direction {display:none;}*/
	/*.feature08 .slider-m {display:block;margin-top:40px;}*/
	/*.feature08 .slider-w {display:none;}*/

	.feature09 {
		padding: 80px 0;
		background: url('./assets/images/bg_is-on-m.png') no-repeat;
		background-size: cover;
	}
	.feature09 ul {
		margin: 40px auto 0;
	}
	.feature09 li {
		width: 140px;
		margin: 48px 14px 0;
	}
	.feature09 li:last-child {
		width: 306px;
	}
	.feature09 li figure img {
		height: 80px;
	}
	.feature09 li p {
		margin-top: 18px;
		font-size: 13px;
		line-height: 1.54;
		letter-spacing: -0.5px;
	}

	.feature10 {
		padding: 80px 24px 32px 34px;
	}
	.feature10 > ul {
		margin: 40px auto 0;
	}
	.feature10 > ul > li {
		position: relative;
		float: none;
		width: 100%;
		display: block;
		min-height: auto;
		margin-bottom: 48px;
	}
	.feature10 .info {
		margin-left: 0;
		padding-top: 0;
	}
	.feature10 .info div {
		position: absolute;
		top: 140px;
		left: 164px;
	}
	.feature10 .info ul {
		margin-top: 32px;
	}

	/* footer */
	footer {
		background: url('./assets/images/bg_footer-m.png') no-repeat;
		background-size: cover;
	}
	footer .inner-box {
		position: relative;
		display: block;
		padding: 56px 34px 40px;
	}
	footer .cont02 {
		padding-top: 295px;
	}
	footer .cont02 > div {
		float: left;
		margin-left: 64px;
	}
	footer .cont03 {
		position: absolute;
		top: 190px;
		left: 34px;
		width: 280px;
	}
	footer .cont03 .footer-link li {
		margin: 0 24px 18px 0;
	}
	footer .copy {
		line-height: 44px;
		text-align: left;
		padding-left: 34px;
	}
}
